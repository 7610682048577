<div class="menu">
    <div class="menu_left" >
        <h3 [ngClass]="pageActive === '/' ? 'menu_left_selected' : ''" routerLink="/">HOME</h3>
        <h3 [ngClass]="pageActive === '/quienes-somos' ? 'menu_left_selected' : ''" routerLink="/quienes-somos">¿QUIÉNES SOMOS?</h3>
        <h3 [ngClass]="pageActive === '/galeria' ? 'menu_left_selected' : ''" routerLink="/galeria">GALERÍA</h3>
        <h3 [ngClass]="pageActive === '/contacto' ? 'menu_left_selected' : ''" routerLink="/contacto">CONTACTO</h3>
    </div>
    <div class="menu_links" routerLink="/">
        <img src="assets/img/logo.png">
    </div>
    <div class="menu__right" >
        <div class="container-shadow">
            <h3>SI QUIERES DESCONECTAR, </h3>
            <div class="">
                <h3 class="shadow-text" > SOMOS LO QUE BUSCAS.</h3>
                <div class="shadow--full"></div>
            </div>
        </div>
        
    </div>

</div>