<div class="contacto">
    <div class="contacto__left">
        <div class="contacto__left_info">
            <img class="contacto__img--montse" src="assets/img/montse.png">
            <div class="contacto__left_info--text">
                <div>
                    <p>¡Hola! ¿Qué tal?</p>
                    <p>Yo soy Montse, propietaria de la casa rural Antolí y estaré encantada de ayudarte en todo lo que
                        pueda.</p>
                    <p>¡No dudes en preguntarme cualquier cosa!</p>
                </div>

                <div class="contacto__left_info--telf">
                    <img class="contacto__img--icon" src="assets/img/phone_call.png">
                    <img class="contacto__img--icon" src="assets/img/phone.png">
                    <div>
                        <p class="text-light">CONTESTO LLAMADAS Y WHATSAPP</p>
                        <p><b>600 041 734</b></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="contacto__left_adress">
            <div class="contacto__left_adress--container">
                <button class="contacto__right--section-button" (click)="openMap()">ABRIR MAPA</button>
               <div class="contacto__left_adress--container-text">
                   <div>
                       <p *ngIf="!isMobile" class="contacto__left_adress--text">CARRER MARE DE DÉU D'AGRES, 2,</p>
                       <p *ngIf="!isMobile" class="contacto__left_adress--text"> 03837 AGRES, ALICANTE</p>
                       <p *ngIf="isMobile" class="contacto__left_adress--text">CARRER MARE DE DÉU D'AGRES, 2, 03837 AGRES, ALICANTE</p>
                    </div>
                   <img class="contacto__img--icon contacto__left_adress--icon" src="assets/img/ubicacion.png">
                </div> 
            </div>
            <div id="mapa"></div>
        </div>
    </div>

    <div class="contacto__right">
        <div class="contacto__right--section">
            <div class="container-shadow">
                <div class="contacto__right--section-title">
                    <h3 class="shadow-text" >CÓMO LLEGAR</h3>
                    <div class="shadow"></div>
                </div>
            </div>
            <p>
                <b>¿Vienes en avión?</b><br>
                Aeropuerto de Alicante (54,8 Km)<br>
                Aeropuerto de Valencia (79,5 Km)<br>
                <b>¿Vienes en tren?</b><br>
                Estación de Agres<br>
                Aeropuerto de Valencia (79,5 Km)
            </p>
        </div>

        <div class="contacto__right--section">
            <div class="container-shadow">
                <div class="contacto__right--section-title">
                    <h3 class="shadow-text" >TARIFAS</h3>
                    <div class="shadow"></div>
                </div>
            </div>
                <p>
                Varía en función del número de noches, personas y temporada.<br>
                Me gusta partir de una base de 14€ por persona y noche, pero lo mejor será que me cuentes tus planes y veamos que podemos hacer.
            </p>
        </div>

        <div class="contacto__right--section">
            <div class="container-shadow">
                <div class="contacto__right--section-title">
                    <h3 class="shadow-text" >AGRES</h3>
                    <div class="shadow"></div>
                </div>
            </div>
            <p>
                Si quieres saber más sobre el encantador y pintoresco pueblo de Agres, te recomiendo que visites este enlace:
            </p>
            <div class="contacto__right--section-container-button">
                <button class="contacto__right--section-button" (click)="openWindow()">DESCUBRE AGRES</button>
            </div>
        </div>
    </div>
</div>