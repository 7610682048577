<div class="quienes-somos">

    <div class="quienes-somos__section quienes-somos__section-left">
        <h2 class="quienes-somos__title">¿Quiénes somos?</h2>
        <p>
            La <b>Casa Rural Antolí</b> se encuentra en un entorno ideal, en el centro del <b>Pueblo de Agres</b>, con
            todos los servicios necesarios a su alcance: ambulatorio, bares, panadería, etc.<br>
            Además, es el lugar idóneo para desconectar, por el entorno tranquilo y la cercanía a cantidad de rutas.<br>
            <b>Casa Antolí se compone por dos viviendas</b> juntas pero independientes, con todas las comodidades y
            totalmente equipadas, para disfrutar en familia o amigos. Cada una de ellas admite un máximo de 6 personas
            (o 12 en caso de alquilar las 2), pero si sois menos no tenemos problema en ajustar el precio.<br>
            <b>¡No dudes en preguntar!</b> Seguro que llegamos a un acuerdo
        </p>
        <p>
            Cada estancia se divide en:<br>
            - Un <b>salón comedor</b> con cocina americana, muy luminoso con un conjunto de sillones y una butaca,
            enfrente un mueble de madera con televisión de plasma.<br>
            - La <b>cocina</b> cuenta con todos los electrodomésticos y el menaje, además de con una puerta de acceso al
            balcón.<br>
            - <b>3 dormitorios.</b> 2 de ellos con dos camas de 1,05 y el tercero con una cama de matrimonio. Desde este
            se accede a una terraza con unas preciosas vistas a todo el valle. Los colchones son de alta calidad y
            disponemos de ropa de cama.<br>
            Todo el inmueble es <b>exterior con vistas.</b>
        </p>
    </div>

    <div class="quienes-somos__section quienes-somos__section-right">
        <p>
            - Un <b>baño</b> totalmente equipado con bañera, un radiador y conjunto de toallas que pueda necesitar.<br>
            <b>La casa está toda climatizada,</b> con calefacción central, con radiadores de agua caliente y
            acondicionado frío calor.
        </p>
        <p>
            Sin duda, una de las características más destacables de la casa Rural Antolí es su <b>entorno:</b> Agres es
            un pueblo tranquilo, con espacios que te van a sorprender.
        </p>
        <p>
            <img class="quienes-somos--pueblo" src="assets/img/pueblo.png">
        </p>
        <p>
            <b>Se puede hacer rutas de senderismo y montaña.</b><br>
            *Casco Urbano: La Iglesia y la plaza.
        </p>
        <p class="no-margin-bot">
            <b>*Lugares de interés:</b><br>
            - <b>El convent</b>(Santuario) (15 minutos)<br>
            - <b>Barranco de la Font Jordana</b>(5,2 km)<br>
            - <b>Las Cavas</b>(2 horas)<br>
            - <b>Montcabrer</b>(2 horas)<br>
            - <b>Molí Mató</b>
        </p>
        <img class="quienes-somos--flor" src="assets/img/rama.png">
    </div>

</div>